import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "../components/global/Button";
import CheckBox from "../components/global/CheckBox";
import Input from "../components/global/Input";
import Loader from "../components/global/Loader";
import { postMessageToParent, signup, updatePlan } from "./Client";
import {
  ALT_MEMBER_TOKEN_NAME,
  FRONTEND_BASE_URL,
  MEMBER_TOKEN_MAX_AGE,
  RTV_BASE_URL
} from "./constants";
import { JourneyType, JOURNEY_COLOURS, JOURNEY_CTA_TEXT } from "./Theme";
import "../styles/free_day_pass/signup.scss";
import { ReactComponent as VFLogo } from "../svg/vodafone_logo.svg";
import { ReactComponent as VMLogo } from "../svg/virgin_media_white.svg";
import EIRLogo from "../svg/Eir-logo-Homepage.png";
import { parseSearch } from "./helpers";
import { trackUserOption } from "../utils/tracking";

export type SignupProps = {
  journey: JourneyType;
};

const Signup: React.FC<SignupProps> = ({ journey }) => {
  const history = useHistory();
  const CONSENT_MARKETING_ID = "consent_marketing";
  const CONSENT_THIRD_PARTY_ID = "consent_third_party";
  const CONSENT_RACE_COURSE_AFFILIATES_ID = "consent_racecourse_affiliates";
  const CONSENT_AGE = "consent_age";
  const IS_MOBILE = window.matchMedia("only screen and (max-width: 760px)").matches;
  const first_Name = sessionStorage.getItem("firstName") || "";
  const last_Name = sessionStorage.getItem("lastName") || "";
  // if(first_Name == "undefined") first_Name = undefined;

  useEffect(() => {
    trackUserOption({ event: "data_entry_reached" });
    postMessageToParent({
      logEvent: { key: `sign-up-modal-page-one-open`, data: {} }
    });
  }, []);

  const [firstName, setFirstName] = React.useState(
    first_Name && first_Name != "undefined" ? first_Name : ""
  );
  const [lastName, setLastName] = React.useState(
    last_Name && last_Name != "undefined" ? last_Name : ""
  );
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [error, setError] = React.useState("");
  const [alreadyExistsError, setAlreadyExistsError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const VODA_FONE_URL = "https://n.vodafone.ie/shop/tv.html";
  const plan = parseSearch(history.location.search, "plan");
  const redeemingPass = window.location.search.includes("redeemingPass=true");

  const VerifyAndProceed = (
    e: any,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    passwordConfirm: string
  ) => {
    setLoading(true);

    // use client side validation to stop and check for errors
    const form = document.getElementById("signup-form") as HTMLInputElement;
    if (!form.checkValidity()) {
      setError(form.validationMessage);
      setLoading(false);
      return;
    }

    e.preventDefault();

    // now set custom errors
    if (password !== passwordConfirm) {
      setError("Sorry. Your passwords don't match.");
      setLoading(false);
      return;
    }

    const consent_age_checked = (document.getElementById(CONSENT_AGE) as HTMLInputElement).checked;
    if (!consent_age_checked) {
      setLoading(false);
      setError("You must be over 18 to use this website.");
      return;
    }

    const consent_marketing = (document.getElementById(CONSENT_MARKETING_ID) as HTMLInputElement)
      .checked
      ? "1"
      : "0";

    const consent_third_party = (
      document.getElementById(CONSENT_THIRD_PARTY_ID) as HTMLInputElement
    ).checked
      ? "1"
      : "0";

    const consent_racecourse_affiliates = (
      document.getElementById(CONSENT_RACE_COURSE_AFFILIATES_ID) as HTMLInputElement
    ).checked
      ? "1"
      : "0";

    const free_account = journey === "free_day_pass" ? "true" : "false";
    signup(
      firstName,
      lastName,
      email,
      password,
      consent_marketing,
      consent_third_party,
      consent_racecourse_affiliates,
      free_account
    )
      .then((response) => {
        sessionStorage.setItem("firstName", firstName);
        sessionStorage.setItem("lastName", lastName);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("memberId", response.member.id);
        sessionStorage.setItem("memberToken", response.jwt_token);

        localStorage.setItem("memberToken", response.jwt_token);
        localStorage.setItem("memberId", response.member.id);
        localStorage.setItem("email", email);
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("lastName", lastName);

        // trigger the dataLayer event
        trackUserOption({
          join_option_selected: {
            consent_marketing,
            consent_third_party,
            consent_racecourse_affiliates
          },
          event: "data_entry_completed"
        });

        // log user in in the background
        postMessageToParent(
          { action: "background-login", jwt_token: response.jwt_token },
          FRONTEND_BASE_URL
        );

        if (Object.keys(plan)?.length) {
          updatePlan(
            {
              cart: {
                id: plan.id.toString(),
                plan_type: plan.plan_type
              }
            },
            response.jwt_token
          )
            .then((res) => {
              sessionStorage.setItem("planSelected", JSON.stringify(plan));

              // setLoading(false);

              // for (let i = 0; i < localStorage.length; i++) {
              //   let key_data = localStorage.key(i) || "";
              //   sessionStorage.setItem(key_data, localStorage.getItem(key_data) || "");
              // }
              history.push(`/join/your-details?token=${response.jwt_token}`);
            })
            .catch((err) => {
              setLoading(false);
              setError(err.message);
            });
        } else {
          journey === "free_day_pass" || redeemingPass
            ? history.push(`/fdp/verify?token=${response.jwt_token}`)
            : history.push(`/join/choose-package?token=${response.jwt_token}`);
        }
      })
      .catch((error) => {
        postMessageToParent("signup_error");
        if (error.toString() === "Error: Username already exists") {
          setAlreadyExistsError(true);
          setError("");
        } else {
          setAlreadyExistsError(false);
          setError(error.toString());
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {journey === "free_day_pass" ? (
        <>
          <div className="free-day-pass-login center">
            <h1 className="free_day_pass_header_text">Racing TV Member</h1>
            <Button
              type="submit"
              text="Log In"
              size="x-small"
              color="accent"
              rightComponent={null}
              onClick={() => history.push("/login")}
            />
          </div>
          <hr className="wide-free-day-pass"></hr>
          <h1 className={JOURNEY_COLOURS[journey] + " center free_day_pass_header_text"}>
            New to Racing TV?
            {/* Try us out for <span className="bold-heading-accent caps">free</span> */}
          </h1>

          <p className="center">
            Register below and receive a unique code to start watching live racing with a Free Day
            Pass
          </p>
        </>
      ) : null}

      {journey === "join" && plan?.name?.length ? (
        <>
          <span className="racing-heading">{plan.form_heading}</span>
          <p className="center racecourse-text ">{plan.form_sub_text}</p>
        </>
      ) : null}
      {journey === "join" && !plan?.name?.length ? (
        <>
          <span className="racing-heading"> Start watching live racing </span>
          <p className="center racecourse-text ">
            From 61 of the best British and Irish racecourses
          </p>
        </>
      ) : null}
      <form id="signup-form">
        <div className="columns">
          <Input
            placeHolder="First name"
            required
            value={firstName ? firstName : ""}
            updateFunc={(v: any) => setFirstName(v.target.value)}
          />
          <span className="row-spacer"></span>
          <Input
            placeHolder="Last name"
            required
            value={lastName ? lastName : ""}
            updateFunc={(v: any) => setLastName(v.target.value)}
          />
        </div>
        <Input
          placeHolder="Email"
          required
          type="email"
          value={email || sessionStorage.getItem("Email") || ""}
          updateFunc={(v: any) => setEmail(v.target.value)}
        />
        <Input
          placeHolder="Password"
          required
          value={password}
          type="password"
          updateFunc={(v: any) => setPassword(v.target.value)}
        />
        <Input
          placeHolder="Confirm Password"
          required
          value={passwordConfirm}
          type="password"
          updateFunc={(v: any) => setPasswordConfirm(v.target.value)}
        />
        <div>
          {alreadyExistsError ? (
            <p className="error-text">
              Email already exists. Please enter a different email or
              <span onClick={() => history.push("/login")} className="white-link">
                {" "}
                sign in here
              </span>
            </p>
          ) : null}
        </div>
        <div className="checkboxes">
          <CheckBox text="I am over 18 years of age*" id={CONSENT_AGE} key="consent_age" />
          <CheckBox
            text="I would like to be contacted by Racing TV about news,
            free tickets, Day Pass and membership offers and other marketing"
            id={CONSENT_MARKETING_ID}
            key={"consent_marketing"}
          />
          <CheckBox
            text={[
              "I would like to be contacted by Racing TV in relation to betting offers and tips, including messages from ",
              <span
                onClick={() =>
                  postMessageToParent({ navigate: true, internalUrl: "/privacy-policy" })
                }
                className={`${JOURNEY_COLOURS[journey]}-link`}>
                bookmaker partners{" "}
              </span>
            ]}
            id={CONSENT_THIRD_PARTY_ID}
            key={"consent_third_party"}
          />
          <CheckBox
            text={[
              "I want to hear from ",
              <span
                onClick={() => postMessageToParent({ navigate: true, internalUrl: "/racecourses" })}
                className={`${JOURNEY_COLOURS[journey]}-link`}>
                Racing TV racecourses{" "}
              </span>,
              "about ticket and hospitality offers"
            ]}
            id={CONSENT_RACE_COURSE_AFFILIATES_ID}
            key={"conset_racecourse_affiliates"}
          />
        </div>
        <p className="error-text m-0">{error}</p>
        <div className="center">
          <Button
            type="submit"
            text={JOURNEY_CTA_TEXT[journey]}
            size="medium"
            color={JOURNEY_COLOURS[journey]}
            rightComponent={loading ? <Loader color={JOURNEY_COLOURS[journey]} /> : null}
            onClick={(e: any) => {
              VerifyAndProceed(e, firstName, lastName, email, password, passwordConfirm);
              postMessageToParent({
                logEvent: { key: "sign-up-modal-page-one-get-started", data: {} }
              });
            }}
          />
        </div>
        {journey === "free_day_pass" ? (
          <>
            <hr className="wide"></hr>
          </>
        ) : null}
      </form>

      <div className="fine-print">
        <p>
          You can unsubscribe from our emails any time and manage your contact preferences in
          Preferences. For more information about how Racing TV uses your data, please read our
          <span
            className={`${JOURNEY_COLOURS[journey]}-link`}
            onClick={() => {
              postMessageToParent({ navigate: true, internalUrl: "/privacy-policy" });
            }}>
            {" "}
            privacy policy
          </span>
        </p>
      </div>

      <div className="d-flex">
        <div className="logo">
          <VMLogo />
        </div>
        <p>
          <strong> Virgin Media</strong> customers who wish to watch live racing will need to
          upgrade their package by calling (UK) 0345 454 1111 or (ROI) 1800 940 070
        </p>
      </div>
      <div className="d-flex">
        <div className="logo">
          <VFLogo />
        </div>
        <p>
          <strong> Vodafone</strong> customers in Ireland can call 1907 or go to{" "}
          <span
            className={`${JOURNEY_COLOURS[journey]}-link`}
            onClick={() => postMessageToParent({ navigate: true, externalUrl: VODA_FONE_URL })}
            title={VODA_FONE_URL}>
            Vodafone Shop
          </span>
        </p>
      </div>
      <div className="d-flex">
        <div className="logo">
          <img src={EIRLogo} alt="Eir TV logo" className="eir-logo" />
        </div>
        <p>
          <strong> eir TV</strong> Call 1901 to add Racing TV (Ireland only)
        </p>
      </div>
      {journey === "free_day_pass" ? (
        <>
          <hr className="wide"></hr>
          <h1 className="center racing_tv_text">Want to watch racing all year round?</h1>
          <div className="center">
            <Button
              type="submit"
              text="Join Racing TV"
              size="medium"
              color="accent"
              rightComponent={null}
              onClick={() => (window.location.href = "/join")}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default Signup;
