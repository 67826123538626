import React, { ReactElement } from "react";
import "../../styles/global/dropText.scss";
import Input from "./Input";
import { ReactComponent as Chevron } from "../../svg/chevron.svg";
import { addDiscountCode, postMessageToParent } from "../../shared/Client";
import Loader from "./Loader";
import Button from "./Button";
import CheckBox from "./CheckBox";
import { SkyQ } from "../../join/YourDetails";
import { AsyncLocalStorage } from "async_hooks";

export type DropTextProps = {
  text: string;
  label: string;
  value: string;
  setValue: Function;
  minLength?: number;
  maxLength?: number;
  discount?: boolean;
  skyQCheckbox?: ReactElement<any, any>;
  showSkyQCheckbox?: boolean;
  open?: boolean;
  placeHolder?: string;
  passFreeDayCode?: boolean;
  setNoPaymentMethod?: any;
};

const DropText: React.FC<DropTextProps> = ({
  text,
  label,
  minLength,
  maxLength,
  value,
  setValue,
  discount = false,
  skyQCheckbox,
  showSkyQCheckbox = false,
  placeHolder,
  open,
  passFreeDayCode,
  setNoPaymentMethod
}) => {
  const [hidden, setHidden] = React.useState<boolean>(open ? !open : true);
  const [discountCodeError, setDiscountCodeError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [successNotice, setSuccessNotice] = React.useState(null);

  const validateDiscountCode = (code: string, passFreeDayCode: any) => {
    setDiscountCodeError(null);
    setSuccessNotice(null);
    setLoading(true);

    addDiscountCode(code, passFreeDayCode)
      .then((res) => {
        if (res.status == "valid") setNoPaymentMethod(true);
        localStorage.setItem("noPaymentMethod", "true");
        setSuccessNotice(res.message);
        postMessageToParent({
          logEvent: { key: `sign-up-modal-page-three-promotional-code-add-success`, data: {} }
        });
      })
      .catch((error: any) => {
        setDiscountCodeError(error.toString());
        setNoPaymentMethod(false);
        postMessageToParent({
          logEvent: { key: `sign-up-modal-page-three-promotional-code-add-invalid`, data: {} }
        });
      })
      .finally(() => setLoading(false));
  };

  const vcnNumberFormat = (vcn: string) => {
    vcn = vcn.replace(/\D/g, "");

    if (vcn.length > 3) vcn = vcn.replace(/.{3}/, "$&-");
    if (vcn.length > 7) vcn = vcn.replace(/.{7}/, "$&-");
    setValue(vcn);
  };

  return (
    <div className="droptext-container">
      <div className="label" onClick={() => setHidden(!hidden)}>
        <span>{text}</span>
        <Chevron className={`rotate ${hidden ? "down" : ""} `} />
      </div>
      {hidden ? null : (
        <>
          <div
            className={
              discount
                ? "droptext-input slide-bottom input-and-button"
                : "slide-bottom droptext-input"
            }>
            <Input
              label={label}
              type="text"
              disabled={!!successNotice}
              minLength={minLength}
              maxLength={maxLength}
              value={value}
              placeHolder={placeHolder}
              updateFunc={(v: any) => {
                text === "Activate on Sky"
                  ? vcnNumberFormat(v.target.value)
                  : setValue(v.target.value);
              }}
            />
            {!discount ? null : (
              <>
                <Button
                  type="submit"
                  text="Add"
                  size="x-small"
                  className="space-left"
                  disabled={!value.length || !!successNotice}
                  color="accent"
                  rightComponent={loading ? <Loader color="accent-secondary" /> : null}
                  onClick={() => validateDiscountCode(value, passFreeDayCode)}
                />
              </>
            )}
            {skyQCheckbox && showSkyQCheckbox ? skyQCheckbox : null}
          </div>
          {discountCodeError ? <p className="error-text">{discountCodeError}</p> : null}
          {successNotice ? <p className="success-text">{successNotice}</p> : null}
        </>
      )}
    </div>
  );
};

export default DropText;
