import React, { useEffect, useState } from "react";
import Button from "../components/global/Button";
import { useHistory, useLocation } from "react-router-dom";
import { JourneyType, JOURNEY_COLOURS } from "../shared/Theme";

import RadioGroup from "../components/global/RadioGroup";
import ProgressBar from "../components/global/ProgressBar";
import Loader from "../components/global/Loader";
import { getAvailablePlans, postMessageToParent, updatePlan } from "../shared/Client";
import { CartUpdate, DiscountPlan, Plan } from "../shared/Models";
import { trackUserOption } from "../utils/tracking";
import { formatEventName } from "../utils/format";

export type ChoosePackageProps = {
  journey: JourneyType;
};

const ChoosePackage: React.FC<ChoosePackageProps> = ({ journey }) => {
  const history = useHistory();
  const lastSelectedPlan = sessionStorage.getItem("planSelected");
  const [plansLoading, setPlansLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availablePlans, setAvailablePlans] = useState<Plan[]>([]);
  const [availableDiscountPlans, setAvailableDiscountPlans] = useState<DiscountPlan[]>([]);
  const [currencyCode, setCurrencyCode] = useState<"GBP" | "EUR">(null);
  const location: any = useLocation();

  const [planSelected, setPlanSelected] = useState<Plan | DiscountPlan | undefined>(
    lastSelectedPlan ? JSON.parse(lastSelectedPlan) : undefined
  );
  const [error, setError] = useState("");

  // values passed from url
  const token = new URLSearchParams(location.search).get("token");
  const selectedPlan = new URLSearchParams(location.search).get("plan");

  const ChoosePackageAndProceed = () => {
    setLoading(true);
    // post to server to ChoosePackage code
    // errors present?
    //     yes: setErrorState
    //    no: go to success to ChoosePackage
    // set any data in the parent frame.
    if (!token) {
      setError("Invalid authentication - please start again.");
      return setLoading(false);
    }

    // debugger;
    if (sessionStorage.getItem("memberToken") != token && token != "undefined") {
      // debugger;
      sessionStorage.setItem("memberToken", token);

      // setError("Invalid authentication - please start again.");
      // return setLoading(false);
    }

    if (!planSelected) {
      setError("Please select a plan");
      return setLoading(false);
    }

    // todo, there's a plan to construct a different payload
    // depending on whether the plan is a discount or not
    // discount plan -> { id: 1 }
    // normal plan -> { plan: 'Monthly' }
    let planSelectedJson: { plan: string | number };

    // const SUPPORTED_PLAN_NAMES = ["Monthly", "Annually"];

    // if (!planSelected.is_discount_plan) {
    //   sessionStorage.setItem(
    //     "plan_supports_sky_q",
    //     SUPPORTED_PLAN_NAMES.includes(planSelected.name).toString()
    //   );
    //   planSelectedJson = { plan: planSelected.name };
    // } else {
    //   const replaceePlanName =
    //     availablePlans.find((p) => p.id === planSelected.plan_id)?.name || "unknown";
    //   console.log("replaceePlanName: ", replaceePlanName);
    //   sessionStorage.setItem(
    //     "plan_supports_sky_q",
    //     SUPPORTED_PLAN_NAMES.includes(replaceePlanName).toString()
    //   );
    //   planSelectedJson = { plan: planSelected.id };
    // }
    // sessionStorage.setItem("freeDayPasscode", planSelected.name);

    const json: CartUpdate = {
      cart: {
        id: planSelected.id.toString(),
        plan_type: planSelected.plan_type
      }
    };

    updatePlan(json, token)
      .then((res) => {
        sessionStorage.setItem("planSelected", JSON.stringify(planSelected));
        setLoading(false);

        // for (let i = 0; i < localStorage.length; i++) {
        //   let key_data = localStorage.key(i) || "";
        //   sessionStorage.setItem(key_data, localStorage.getItem(key_data) || "");
        // }

        // trigger event to track user option
        trackUserOption({
          join_option_selected: { plan_name: planSelected.name },
          event: "select_package_completed"
        });
        postMessageToParent({
          logEvent: {
            key: `sign-up-modal-page-two-${formatEventName(planSelected.name)}-selected`,
            data: {
              package_name: planSelected.name
            }
          }
        });
        postMessageToParent({
          logEvent: { key: `sign-up-modal-page-two-continue`, data: {} }
        });

        history.push(`/join/your-details?token=${token}`);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  // on page load call getPlans from client
  useEffect(() => {
    setPlansLoading(true);
    if (!token) {
      setError("Invalid authentication");
      return setPlansLoading(false);
    }

    getAvailablePlans(token)
      .then((res) => {
        setError("");
        // augment with flag to say which they are
        // res.available_plans.map((p: Plan) => {
        //   p["is_discount_plan"] = false;
        //   p["currency_code"] = res.currency_code;
        // });

        // res.discount_plans.map((p: DiscountPlan) => {
        //   p["is_discount_plan"] = true;
        //   p["currency_code"] = res.currency_code;
        // });

        setAvailablePlans(res.available_plans);
        setAvailableDiscountPlans(res.discount_plans);
        setCurrencyCode(res.currency_code as any);
        setPlansLoading(false);
        if (selectedPlan) {
          const allPlans = [...res.available_plans, ...res.discount_plans];
          const p = allPlans.find((pl) => pl.id === parseInt(selectedPlan));
          setPlanSelected(p);
        }
      })
      .catch((err) => {
        console.log(err);
        setPlansLoading(false);
        setError(err.message);
      });
  }, [location]);

  return (
    <>
      <ProgressBar activeDots={2} numberOfDots={4} />
      <h1 className={JOURNEY_COLOURS[journey] + " m-0 name-text"}>SELECT PACKAGE</h1>
      {plansLoading ? <Loader color="accent" /> : null}
      <RadioGroup
        handler={setPlanSelected}
        type="paymentDuration"
        journey={journey}
        currencyCode={currencyCode}
        discountPlans={availableDiscountPlans}
        availablePlans={availablePlans}
        planSelected={planSelected}
      />
      {error ? <p className="error-text">{error}</p> : null}
      <div className="center">
        <Button
          type="submit"
          text="Continue"
          size="medium"
          disabled={!planSelected}
          color={JOURNEY_COLOURS[journey]}
          rightComponent={loading ? <Loader color={JOURNEY_COLOURS[journey]} /> : null}
          onClick={() => ChoosePackageAndProceed()}
        />
      </div>
    </>
  );
};

export default ChoosePackage;
